import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
library.add(fas)

class BlogRoll3 extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&  posts.splice(3,100) &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-4" key={post.id}>
              <div
                className={`tile is-child box is-white ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className="post-meta has-text-centered">
                    <p style={{ marginBottom: '10px', marginTop: '10px' }}>
                      <Link
                        className="is-size-6"
                        to={post.fields.slug}
                        style={{ color: '#333' }}
                      >
                        <strong>
                          {post.frontmatter.title}
                        </strong>
                      </Link>
                    </p>
                    <p className="subtitle is-size-7 is-block has-text-centered" style={{ marginBottom: '10px', marginTop: '10px' }}>
                      {post.frontmatter.date}
                    </p>
                  </p>
                </header>
                <p className="is-size-7 has-text-centered">
                  {post.excerpt}
                  <br />
                  <br />
                  <Link class="button is-small is-light" to={post.fields.slug}>
                    <span>Keep Reading</span>
                    <span class="icon is-small">
                      <FontAwesomeIcon icon={['fas', 'angle-double-right']} />
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll3.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRoll3Query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "daily-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll3 data={data} count={count} />}
  />
)
