import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
library.add(fas)

class NewsRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts && posts.splice(3,100) &&
          posts.map(({ node: post }) => (
            <div className="column is-12" key={post.id}>
              <div
                className={`tile is-child is-white ${
                  post.frontmatter.featuredpost ? "is-featured" : ""
                }`}
              >
                <header>
                  <p className="has-text-centered	">
                    <span>
                      <canter>
                      <span className="is-size-6 has-text-weight-normal">{post.frontmatter.date}</span><br/>
                      </canter>
                      
                      <Link
                        to={post.fields.slug}
                        // style={{ color: "#333" }}
                      >
                        <div className="is-size-6 "style={{ marginLeft: "30px", marginRight: "30px" }}>
                          <span className="is-size-6-desktop is-size-7-touch">{post.frontmatter.title}</span>
                        </div>
                      </Link>
                    </span>
                  </p>
                </header>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

NewsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query NewsRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "news-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 500)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <NewsRoll data={data} count={count} />}
  />
)
