import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import BlogRoll3 from '../components/BlogRoll3'
import NewsRoll from "../components/NewsRoll"

import about from "../img/about.jpg";
import bg from "../img/bg.jpg";
import nz from "../img/naze.jpg";
import inputcone from "../img/inputcone.jpg";

import news from "../img/news.jpg";

import nagaoka from "../img/about/nagaoka.jpg"
import golden from "../img/about/golden2.jpg"
import sslex from "../img/about/sslex.jpg"
import kyosan from "../img/about/kyosan.jpg"
import anaze from "../img/about/naze.jpg"
import dream from "../img/about/dream.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
library.add(fas)

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro
}) => (
  <div>
    {/* < YouTube className="full-width-image margin-top-0"
        videoId={"z6XRd0bXM7A"}
        opts={opts}
      >
      </YouTube> */}
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${about})`,
        backgroundSize: `cover`,
        backgroundPosition: `50% 50%`
      }}
    >
      <div
        className="full-width-image-container"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
        }}
      >
        < div className="has-text-centered" >
          <strong
            className="is-size-3-desktop is-size-5-touch"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            私たちは、 長岡をホームに活動する<br />ロボットサッカーチームです。
          </strong>
        </div >
      </div>
    </div>
    <div className="column is-8 is-offset-2">
      <div className="content">
        <div className="columns is-multiline is-vcentered">
          <div className="is-parent column is-6">
            <img
              className="margin-btm-0"
              src={inputcone}
              alt=""
              width="100%"
              height="auto"
            />
          </div>
          <div className="is-parent column is-5 is-offset-1 has-text-centered">
            <p><strong
              className="is-size-4-desktop is-size-6-touch">
              INPUTの取り組み
            </strong></p>
            <dev className="about has-text-left">
              <p className="is-size-6-desktop is-size-7-touch">　INPUTは、新潟県長岡市の地元製造企業と連携しロボットを開発しました。NPO法人長岡産業活性化協会NAZEの会員企業を中心とした長岡の地元企業とのロボット開発を通じて、地域活性化を推進するロールモデルを構築しました。</p>
            </dev>
            < div style={
              {
                marginTop: `20px`,
                marginBottom: `100px`
              }
            } >
              <Link class="button is-outlined" to="/aboutinput">
                <span>Read more</span>
                <span class="icon is-small">
                  <FontAwesomeIcon
                    icon={["fas", "angle-double-right"]}
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="full-width-image-container  margin-btm-0"
      style={{
        backgroundImage: `url(${news})`,
        backgroundSize: `cover`,
        backgroundPosition: `50% 60%`
      }}
    >
      <div
        className="full-width-image-container"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "absolute",
        }}
      >
        < div className="has-text-centered" >
          <strong
            className="is-size-3-desktop is-size-5-touch"
            style={{
              color: 'black',
              padding: '1rem',
            }}
          >
            ニュース
          </strong>
          <br />
          <p
            className="is-size-4-desktop is-size-6-touch"
            style={{
              color: 'black',
              padding: '1rem',
            }}
          >
            SNSで最新情報を更新しています。
          </p>
          <br />
          <p >
            <a href="https://www.facebook.com/inputssl" target="_blank" class="button is-outlined" style={{ marginRight: '20px' }}>
              <span class="icon">
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </span>
              <span>Facebook</span>
            </a>
            <a href="https://twitter.com/inputssl" target="_blank" class="button is-outlined">
              <span class="icon is-small">
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </span>
              <span>Twitter</span>
            </a>
          </p>
          <br />
          {/* <Link class="button is-outlined" to="/sns">
            <span>Read more</span>
            <span class="icon is-small">
              <FontAwesomeIcon
                icon={["fas", "angle-double-right"]}
              />
            </span>
          </Link> */}
        </div >
      </div>
    </div>

    {/* <div className="column is-10 is-offset-1">
          <p className=" has-text-weight-semibold is-size-4 has-text-centered">
            最近の出来事
          </p>
          <BlogRoll3 />
          < div className="has-text-centered"
            style={
              {
                marginTop: `10px`,
                marginBottom: `100px`
              }
            } >
            <Link class="button is-outlined" to="/daily">
              <span>Read more</span>
              <span class="icon is-small">
                <FontAwesomeIcon
                  icon={["fas", "angle-double-right"]}
                />
              </span>
            </Link>
          </div>
        </div> */}
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: `cover`,
        backgroundPosition: `50% 20%`
      }}
    >
      <div
        className="full-width-image-container"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          position: "absolute",
        }}
      >
        < div className="has-text-centered" >
          <strong
            className="is-size-3-desktop is-size-5-touch"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            メンバー紹介
          </strong><br />
          <p
            className="is-size-4-desktop is-size-6-touch"
            style={{
              color: 'white',
              padding: '1rem',
            }}
          >
            INPUTは、長岡出身のメンバーによって構成されています。
          </p>
          < div style={
            {
              marginTop: `10px`
            }
          } >
            <Link class="button is-primary is-inverted is-outlined" to="/Team">
              <span>Read more</span>
              <span class="icon is-small">
                <FontAwesomeIcon
                  icon={["fas", "angle-double-right"]}
                />
              </span>
            </Link>
          </div>
        </div >
      </div>
    </div>
    <div className="column is-10 is-offset-1">
      <div className="content">
          <div
            className="column is-10 is-offset-1"
            style={{
              marginTop: `60px`,
              marginBottom: `80px`,
            }}
          >
            <p className="is-marginless has-text-weight-semibold is-size-4 has-text-centered">
              メディア掲載
            </p>
            <div className="box">
              <NewsRoll />
              < div className="has-text-centered"
                style={
                  {
                    marginTop: `10px`
                  }
                } >
                <Link class="button is-outlined" to="/news">
                  <span>Read more</span>
                  <span class="icon is-small">
                    <FontAwesomeIcon
                      icon={["fas", "angle-double-right"]}
                    />
                  </span>
                </Link>
              </div>
            </div>
        </div>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url(${nz})`,
            backgroundSize: `cover`,
            backgroundPosition: `50% 60%`
          }}
        >
          <div
            className="full-width-image-container"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              position: "absolute",
            }}
          >
            < div className="has-text-centered" >
              <strong
                className="is-size-3-desktop is-size-5-touch"
                style={{
                  color: 'white',
                  padding: '1rem',
                }}
              >
                お問い合わせ
              </strong>
              <br />
              <p
                className="is-size-4-desktop is-size-6-touch"
                style={{
                  color: 'white',
                  padding: '1rem',
                }}
              >
                INPUTへの各種お問い合わせはこちら。
              </p>
              <span>
                <Link class="button is-primary is-inverted is-outlined" to="/contact">
                  <span>お問い合わせフォーム</span>
                </Link>
              </span>
              <br />
            </div >
          </div>
        </div>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
        markdownRemark(frontmatter: {templateKey: {eq: "index-page" } }) {
        frontmatter {
        title
        image {
        childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
    heading
    subheading
        mainpitch {
        title
          description
}
description
        intro {
        blurbs {
        image {
        childImageSharp {
        fluid(maxWidth: 240, quality: 16) {
        ...GatsbyImageSharpFluid
      }
      }
    }
    text
  }
  heading
  description
}
}
}
}
`
